import { default as _91_46_46_46slug_932ieEvKkhM5Meta } from "/data/www/mijn.khn.nl/mijn-khn/releases/34/nuxt/pages/[...slug].vue?macro=true";
import { default as adressen2e4Zm43GmmMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/34/nuxt/pages/account/bedrijfsgegevens/[company]/adressen.vue?macro=true";
import { default as indexPxQp43Ae0tMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/34/nuxt/pages/account/bedrijfsgegevens/[company]/index.vue?macro=true";
import { default as medewerkersWqHH31GxRyMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/34/nuxt/pages/account/bedrijfsgegevens/[company]/medewerkers.vue?macro=true";
import { default as indexuIuRPFpMqkMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/34/nuxt/pages/account/bedrijfsgegevens/index.vue?macro=true";
import { default as indexljo4SD0KUfMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/34/nuxt/pages/account/communicatievoorkeuren/index.vue?macro=true";
import { default as indexYzishbVxh4Meta } from "/data/www/mijn.khn.nl/mijn-khn/releases/34/nuxt/pages/account/evenementen/index.vue?macro=true";
import { default as _91factuur_93Is0ip2rBI7Meta } from "/data/www/mijn.khn.nl/mijn-khn/releases/34/nuxt/pages/account/facturen/[factuur].vue?macro=true";
import { default as indexZw0CUFYD9HMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/34/nuxt/pages/account/facturen/index.vue?macro=true";
import { default as indexIUO8pjo5xrMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/34/nuxt/pages/account/index.vue?macro=true";
import { default as indexGtKi0z53UXMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/34/nuxt/pages/account/ledenvoordelen/index.vue?macro=true";
import { default as incassohd9uKc5WJUMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/34/nuxt/pages/account/lidmaatschap/[lidmaatschap]/incasso.vue?macro=true";
import { default as indexya1oiQa5lPMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/34/nuxt/pages/account/lidmaatschap/[lidmaatschap]/index.vue?macro=true";
import { default as index1Byvd0GbKbMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/34/nuxt/pages/account/lidmaatschap/index.vue?macro=true";
import { default as nieuwsbriefvoorkeurenrWjGkV4SBHMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/34/nuxt/pages/account/nieuwsbriefvoorkeuren.vue?macro=true";
import { default as accountCrZOtGak0rMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/34/nuxt/pages/account.vue?macro=true";
import { default as loginzZKGZb6xU7Meta } from "/data/www/mijn.khn.nl/mijn-khn/releases/34/nuxt/pages/auth/login.vue?macro=true";
import { default as _91token_93ZV6QNsEt4BMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/34/nuxt/pages/auth/wachtwoord-instellen/[token].vue?macro=true";
import { default as _91token_93CAB2jcWlZQMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/34/nuxt/pages/auth/wachtwoord-vergeten/[token].vue?macro=true";
import { default as indexm4JAPg1MonMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/34/nuxt/pages/auth/wachtwoord-vergeten/index.vue?macro=true";
import { default as _91slug_93odVSmkdTGJMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/34/nuxt/pages/bestuurders/[slug].vue?macro=true";
import { default as bestuurdersacademieDmsjkwJsKWMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/34/nuxt/pages/bestuurders/bestuurdersacademie.vue?macro=true";
import { default as dossiers_45documentenPTLEbKQtEQMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/34/nuxt/pages/bestuurders/dossiers-documenten.vue?macro=true";
import { default as indexz25KV5zKPfMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/34/nuxt/pages/bestuurders/index.vue?macro=true";
import { default as bestuurdersf5QfR1aTBvMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/34/nuxt/pages/bestuurders.vue?macro=true";
import { default as dashboardURshKU5qu1Meta } from "/data/www/mijn.khn.nl/mijn-khn/releases/34/nuxt/pages/dashboard.vue?macro=true";
import { default as aanmeldenAUavWgEWkLMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/34/nuxt/pages/evenementen/[slug]/aanmelden.vue?macro=true";
import { default as indexNXRWqat90aMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/34/nuxt/pages/evenementen/[slug]/index.vue?macro=true";
import { default as indexscNMi6pQBiMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/34/nuxt/pages/evenementen/index.vue?macro=true";
import { default as evenementenWdPQHEPVtCMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/34/nuxt/pages/evenementen.vue?macro=true";
import { default as index7wEK5j9RUoMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/34/nuxt/pages/index.vue?macro=true";
import { default as kennisbankyyXcoYNEbTMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/34/nuxt/pages/kennisbank.vue?macro=true";
import { default as _91id_93LrpSKqArtOMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/34/nuxt/pages/klanten/[id].vue?macro=true";
import { default as index66XLlBiTjCMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/34/nuxt/pages/klanten/index.vue?macro=true";
import { default as afnemeneSfqwAiVPmMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/34/nuxt/pages/ledenvoordelen/[slug]/afnemen.vue?macro=true";
import { default as indexuk4Ob1i5CWMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/34/nuxt/pages/ledenvoordelen/[slug]/index.vue?macro=true";
import { default as indexIgRJiR65wlMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/34/nuxt/pages/ledenvoordelen/index.vue?macro=true";
import { default as ledenvoordelenTDM4JTYAojMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/34/nuxt/pages/ledenvoordelen.vue?macro=true";
import { default as indexVqFuuUsFZSMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/34/nuxt/pages/lid-worden/index.vue?macro=true";
import { default as uitschrijvenP6LxIhgCOsMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/34/nuxt/pages/nieuwsbrief/uitschrijven.vue?macro=true";
import { default as authorizey9R4IM7Z5UMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/34/nuxt/pages/oauth/authorize.vue?macro=true";
import { default as stylesqyvAxHd62yMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/34/nuxt/pages/styles.vue?macro=true";
import { default as _91id_930fkYiDhwMFMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/34/nuxt/pages/verkoopkansen/[id].vue?macro=true";
import { default as indexkJqRqUhM7rMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/34/nuxt/pages/verkoopkansen/index.vue?macro=true";
import { default as _91product_93qRfMOs1T7uMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/34/nuxt/pages/webshop/[category]/[product].vue?macro=true";
import { default as indexKDE94fDwNAMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/34/nuxt/pages/webshop/[category]/index.vue?macro=true";
import { default as cartK0mWOqWXEXMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/34/nuxt/pages/webshop/cart.vue?macro=true";
import { default as checkoutCVFk3a2FxBMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/34/nuxt/pages/webshop/checkout.vue?macro=true";
import { default as confirmationE8VwyPxV1yMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/34/nuxt/pages/webshop/confirmation.vue?macro=true";
import { default as indexWYYwcdwTvfMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/34/nuxt/pages/webshop/index.vue?macro=true";
import { default as webshopyUdQ0pzLBtMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/34/nuxt/pages/webshop.vue?macro=true";
import { default as zoekenCDT3ppxMxLMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/34/nuxt/pages/zoeken.vue?macro=true";
import { default as indexD704OlaQYNMeta } from "~/pages/evenementen/[slug]/index.vue?macro=true";
export default [
  {
    name: _91_46_46_46slug_932ieEvKkhM5Meta?.name ?? "slug",
    path: _91_46_46_46slug_932ieEvKkhM5Meta?.path ?? "/:slug(.*)*",
    meta: _91_46_46_46slug_932ieEvKkhM5Meta || {},
    alias: _91_46_46_46slug_932ieEvKkhM5Meta?.alias || [],
    redirect: _91_46_46_46slug_932ieEvKkhM5Meta?.redirect,
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/34/nuxt/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: accountCrZOtGak0rMeta?.name ?? undefined,
    path: accountCrZOtGak0rMeta?.path ?? "/account",
    meta: accountCrZOtGak0rMeta || {},
    alias: accountCrZOtGak0rMeta?.alias || [],
    redirect: accountCrZOtGak0rMeta?.redirect,
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/34/nuxt/pages/account.vue").then(m => m.default || m),
    children: [
  {
    name: adressen2e4Zm43GmmMeta?.name ?? "account-bedrijfsgegevens-company-adressen",
    path: adressen2e4Zm43GmmMeta?.path ?? "bedrijfsgegevens/:company()/adressen",
    meta: adressen2e4Zm43GmmMeta || {},
    alias: adressen2e4Zm43GmmMeta?.alias || [],
    redirect: adressen2e4Zm43GmmMeta?.redirect,
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/34/nuxt/pages/account/bedrijfsgegevens/[company]/adressen.vue").then(m => m.default || m)
  },
  {
    name: indexPxQp43Ae0tMeta?.name ?? "account-bedrijfsgegevens-company",
    path: indexPxQp43Ae0tMeta?.path ?? "bedrijfsgegevens/:company()",
    meta: indexPxQp43Ae0tMeta || {},
    alias: indexPxQp43Ae0tMeta?.alias || [],
    redirect: indexPxQp43Ae0tMeta?.redirect,
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/34/nuxt/pages/account/bedrijfsgegevens/[company]/index.vue").then(m => m.default || m)
  },
  {
    name: medewerkersWqHH31GxRyMeta?.name ?? "account-bedrijfsgegevens-company-medewerkers",
    path: medewerkersWqHH31GxRyMeta?.path ?? "bedrijfsgegevens/:company()/medewerkers",
    meta: medewerkersWqHH31GxRyMeta || {},
    alias: medewerkersWqHH31GxRyMeta?.alias || [],
    redirect: medewerkersWqHH31GxRyMeta?.redirect,
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/34/nuxt/pages/account/bedrijfsgegevens/[company]/medewerkers.vue").then(m => m.default || m)
  },
  {
    name: indexuIuRPFpMqkMeta?.name ?? "account-bedrijfsgegevens",
    path: indexuIuRPFpMqkMeta?.path ?? "bedrijfsgegevens",
    meta: indexuIuRPFpMqkMeta || {},
    alias: indexuIuRPFpMqkMeta?.alias || [],
    redirect: indexuIuRPFpMqkMeta?.redirect,
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/34/nuxt/pages/account/bedrijfsgegevens/index.vue").then(m => m.default || m)
  },
  {
    name: indexljo4SD0KUfMeta?.name ?? "account-communicatievoorkeuren",
    path: indexljo4SD0KUfMeta?.path ?? "communicatievoorkeuren",
    meta: indexljo4SD0KUfMeta || {},
    alias: indexljo4SD0KUfMeta?.alias || [],
    redirect: indexljo4SD0KUfMeta?.redirect,
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/34/nuxt/pages/account/communicatievoorkeuren/index.vue").then(m => m.default || m)
  },
  {
    name: indexYzishbVxh4Meta?.name ?? "account-evenementen",
    path: indexYzishbVxh4Meta?.path ?? "evenementen",
    meta: indexYzishbVxh4Meta || {},
    alias: indexYzishbVxh4Meta?.alias || [],
    redirect: indexYzishbVxh4Meta?.redirect,
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/34/nuxt/pages/account/evenementen/index.vue").then(m => m.default || m)
  },
  {
    name: _91factuur_93Is0ip2rBI7Meta?.name ?? "account-facturen-factuur",
    path: _91factuur_93Is0ip2rBI7Meta?.path ?? "facturen/:factuur()",
    meta: _91factuur_93Is0ip2rBI7Meta || {},
    alias: _91factuur_93Is0ip2rBI7Meta?.alias || [],
    redirect: _91factuur_93Is0ip2rBI7Meta?.redirect,
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/34/nuxt/pages/account/facturen/[factuur].vue").then(m => m.default || m)
  },
  {
    name: indexZw0CUFYD9HMeta?.name ?? "account-facturen",
    path: indexZw0CUFYD9HMeta?.path ?? "facturen",
    meta: indexZw0CUFYD9HMeta || {},
    alias: indexZw0CUFYD9HMeta?.alias || [],
    redirect: indexZw0CUFYD9HMeta?.redirect,
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/34/nuxt/pages/account/facturen/index.vue").then(m => m.default || m)
  },
  {
    name: indexIUO8pjo5xrMeta?.name ?? "account",
    path: indexIUO8pjo5xrMeta?.path ?? "",
    meta: indexIUO8pjo5xrMeta || {},
    alias: indexIUO8pjo5xrMeta?.alias || [],
    redirect: indexIUO8pjo5xrMeta?.redirect,
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/34/nuxt/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: indexGtKi0z53UXMeta?.name ?? "account-ledenvoordelen",
    path: indexGtKi0z53UXMeta?.path ?? "ledenvoordelen",
    meta: indexGtKi0z53UXMeta || {},
    alias: indexGtKi0z53UXMeta?.alias || [],
    redirect: indexGtKi0z53UXMeta?.redirect,
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/34/nuxt/pages/account/ledenvoordelen/index.vue").then(m => m.default || m)
  },
  {
    name: incassohd9uKc5WJUMeta?.name ?? "account-lidmaatschap-lidmaatschap-incasso",
    path: incassohd9uKc5WJUMeta?.path ?? "lidmaatschap/:lidmaatschap()/incasso",
    meta: incassohd9uKc5WJUMeta || {},
    alias: incassohd9uKc5WJUMeta?.alias || [],
    redirect: incassohd9uKc5WJUMeta?.redirect,
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/34/nuxt/pages/account/lidmaatschap/[lidmaatschap]/incasso.vue").then(m => m.default || m)
  },
  {
    name: indexya1oiQa5lPMeta?.name ?? "account-lidmaatschap-lidmaatschap",
    path: indexya1oiQa5lPMeta?.path ?? "lidmaatschap/:lidmaatschap()",
    meta: indexya1oiQa5lPMeta || {},
    alias: indexya1oiQa5lPMeta?.alias || [],
    redirect: indexya1oiQa5lPMeta?.redirect,
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/34/nuxt/pages/account/lidmaatschap/[lidmaatschap]/index.vue").then(m => m.default || m)
  },
  {
    name: index1Byvd0GbKbMeta?.name ?? "account-lidmaatschap",
    path: index1Byvd0GbKbMeta?.path ?? "lidmaatschap",
    meta: index1Byvd0GbKbMeta || {},
    alias: index1Byvd0GbKbMeta?.alias || [],
    redirect: index1Byvd0GbKbMeta?.redirect,
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/34/nuxt/pages/account/lidmaatschap/index.vue").then(m => m.default || m)
  },
  {
    name: nieuwsbriefvoorkeurenrWjGkV4SBHMeta?.name ?? "account-nieuwsbriefvoorkeuren",
    path: nieuwsbriefvoorkeurenrWjGkV4SBHMeta?.path ?? "nieuwsbriefvoorkeuren",
    meta: nieuwsbriefvoorkeurenrWjGkV4SBHMeta || {},
    alias: nieuwsbriefvoorkeurenrWjGkV4SBHMeta?.alias || [],
    redirect: nieuwsbriefvoorkeurenrWjGkV4SBHMeta?.redirect,
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/34/nuxt/pages/account/nieuwsbriefvoorkeuren.vue").then(m => m.default || m)
  }
]
  },
  {
    name: loginzZKGZb6xU7Meta?.name ?? "auth-login",
    path: loginzZKGZb6xU7Meta?.path ?? "/auth/login",
    meta: loginzZKGZb6xU7Meta || {},
    alias: loginzZKGZb6xU7Meta?.alias || [],
    redirect: loginzZKGZb6xU7Meta?.redirect,
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/34/nuxt/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: _91token_93ZV6QNsEt4BMeta?.name ?? "auth-wachtwoord-instellen-token",
    path: _91token_93ZV6QNsEt4BMeta?.path ?? "/auth/wachtwoord-instellen/:token()",
    meta: _91token_93ZV6QNsEt4BMeta || {},
    alias: _91token_93ZV6QNsEt4BMeta?.alias || [],
    redirect: _91token_93ZV6QNsEt4BMeta?.redirect,
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/34/nuxt/pages/auth/wachtwoord-instellen/[token].vue").then(m => m.default || m)
  },
  {
    name: _91token_93CAB2jcWlZQMeta?.name ?? "auth-wachtwoord-vergeten-token",
    path: _91token_93CAB2jcWlZQMeta?.path ?? "/auth/wachtwoord-vergeten/:token()",
    meta: _91token_93CAB2jcWlZQMeta || {},
    alias: _91token_93CAB2jcWlZQMeta?.alias || [],
    redirect: _91token_93CAB2jcWlZQMeta?.redirect,
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/34/nuxt/pages/auth/wachtwoord-vergeten/[token].vue").then(m => m.default || m)
  },
  {
    name: indexm4JAPg1MonMeta?.name ?? "auth-wachtwoord-vergeten",
    path: indexm4JAPg1MonMeta?.path ?? "/auth/wachtwoord-vergeten",
    meta: indexm4JAPg1MonMeta || {},
    alias: indexm4JAPg1MonMeta?.alias || [],
    redirect: indexm4JAPg1MonMeta?.redirect,
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/34/nuxt/pages/auth/wachtwoord-vergeten/index.vue").then(m => m.default || m)
  },
  {
    name: bestuurdersf5QfR1aTBvMeta?.name ?? undefined,
    path: bestuurdersf5QfR1aTBvMeta?.path ?? "/bestuurders",
    meta: bestuurdersf5QfR1aTBvMeta || {},
    alias: bestuurdersf5QfR1aTBvMeta?.alias || [],
    redirect: bestuurdersf5QfR1aTBvMeta?.redirect,
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/34/nuxt/pages/bestuurders.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_93odVSmkdTGJMeta?.name ?? "bestuurders-slug",
    path: _91slug_93odVSmkdTGJMeta?.path ?? ":slug()",
    meta: _91slug_93odVSmkdTGJMeta || {},
    alias: _91slug_93odVSmkdTGJMeta?.alias || [],
    redirect: _91slug_93odVSmkdTGJMeta?.redirect,
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/34/nuxt/pages/bestuurders/[slug].vue").then(m => m.default || m)
  },
  {
    name: bestuurdersacademieDmsjkwJsKWMeta?.name ?? "bestuurders-bestuurdersacademie",
    path: bestuurdersacademieDmsjkwJsKWMeta?.path ?? "bestuurdersacademie",
    meta: bestuurdersacademieDmsjkwJsKWMeta || {},
    alias: bestuurdersacademieDmsjkwJsKWMeta?.alias || [],
    redirect: bestuurdersacademieDmsjkwJsKWMeta?.redirect,
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/34/nuxt/pages/bestuurders/bestuurdersacademie.vue").then(m => m.default || m)
  },
  {
    name: dossiers_45documentenPTLEbKQtEQMeta?.name ?? "bestuurders-dossiers-documenten",
    path: dossiers_45documentenPTLEbKQtEQMeta?.path ?? "dossiers-documenten",
    meta: dossiers_45documentenPTLEbKQtEQMeta || {},
    alias: dossiers_45documentenPTLEbKQtEQMeta?.alias || [],
    redirect: dossiers_45documentenPTLEbKQtEQMeta?.redirect,
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/34/nuxt/pages/bestuurders/dossiers-documenten.vue").then(m => m.default || m)
  },
  {
    name: indexz25KV5zKPfMeta?.name ?? "bestuurders",
    path: indexz25KV5zKPfMeta?.path ?? "",
    meta: indexz25KV5zKPfMeta || {},
    alias: indexz25KV5zKPfMeta?.alias || [],
    redirect: indexz25KV5zKPfMeta?.redirect,
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/34/nuxt/pages/bestuurders/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: dashboardURshKU5qu1Meta?.name ?? "dashboard",
    path: dashboardURshKU5qu1Meta?.path ?? "/dashboard",
    meta: dashboardURshKU5qu1Meta || {},
    alias: dashboardURshKU5qu1Meta?.alias || [],
    redirect: dashboardURshKU5qu1Meta?.redirect,
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/34/nuxt/pages/dashboard.vue").then(m => m.default || m)
  },
  {
    name: evenementenWdPQHEPVtCMeta?.name ?? undefined,
    path: evenementenWdPQHEPVtCMeta?.path ?? "/evenementen",
    meta: evenementenWdPQHEPVtCMeta || {},
    alias: evenementenWdPQHEPVtCMeta?.alias || [],
    redirect: evenementenWdPQHEPVtCMeta?.redirect,
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/34/nuxt/pages/evenementen.vue").then(m => m.default || m),
    children: [
  {
    name: aanmeldenAUavWgEWkLMeta?.name ?? "evenementen-slug-aanmelden",
    path: aanmeldenAUavWgEWkLMeta?.path ?? ":slug()/aanmelden",
    meta: aanmeldenAUavWgEWkLMeta || {},
    alias: aanmeldenAUavWgEWkLMeta?.alias || [],
    redirect: aanmeldenAUavWgEWkLMeta?.redirect,
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/34/nuxt/pages/evenementen/[slug]/aanmelden.vue").then(m => m.default || m)
  },
  {
    name: indexNXRWqat90aMeta?.name ?? "evenementen-slug",
    path: indexNXRWqat90aMeta?.path ?? ":slug()",
    meta: indexNXRWqat90aMeta || {},
    alias: indexNXRWqat90aMeta?.alias || [],
    redirect: indexNXRWqat90aMeta?.redirect,
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/34/nuxt/pages/evenementen/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexscNMi6pQBiMeta?.name ?? "evenementen",
    path: indexscNMi6pQBiMeta?.path ?? "",
    meta: indexscNMi6pQBiMeta || {},
    alias: indexscNMi6pQBiMeta?.alias || [],
    redirect: indexscNMi6pQBiMeta?.redirect,
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/34/nuxt/pages/evenementen/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: index7wEK5j9RUoMeta?.name ?? "index",
    path: index7wEK5j9RUoMeta?.path ?? "/",
    meta: index7wEK5j9RUoMeta || {},
    alias: index7wEK5j9RUoMeta?.alias || [],
    redirect: index7wEK5j9RUoMeta?.redirect,
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/34/nuxt/pages/index.vue").then(m => m.default || m)
  },
  {
    name: kennisbankyyXcoYNEbTMeta?.name ?? "kennisbank",
    path: kennisbankyyXcoYNEbTMeta?.path ?? "/kennisbank",
    meta: kennisbankyyXcoYNEbTMeta || {},
    alias: kennisbankyyXcoYNEbTMeta?.alias || [],
    redirect: kennisbankyyXcoYNEbTMeta?.redirect,
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/34/nuxt/pages/kennisbank.vue").then(m => m.default || m)
  },
  {
    name: _91id_93LrpSKqArtOMeta?.name ?? "klanten-id",
    path: _91id_93LrpSKqArtOMeta?.path ?? "/klanten/:id()",
    meta: _91id_93LrpSKqArtOMeta || {},
    alias: _91id_93LrpSKqArtOMeta?.alias || [],
    redirect: _91id_93LrpSKqArtOMeta?.redirect,
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/34/nuxt/pages/klanten/[id].vue").then(m => m.default || m)
  },
  {
    name: index66XLlBiTjCMeta?.name ?? "klanten",
    path: index66XLlBiTjCMeta?.path ?? "/klanten",
    meta: index66XLlBiTjCMeta || {},
    alias: index66XLlBiTjCMeta?.alias || [],
    redirect: index66XLlBiTjCMeta?.redirect,
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/34/nuxt/pages/klanten/index.vue").then(m => m.default || m)
  },
  {
    name: ledenvoordelenTDM4JTYAojMeta?.name ?? undefined,
    path: ledenvoordelenTDM4JTYAojMeta?.path ?? "/ledenvoordelen",
    meta: ledenvoordelenTDM4JTYAojMeta || {},
    alias: ledenvoordelenTDM4JTYAojMeta?.alias || [],
    redirect: ledenvoordelenTDM4JTYAojMeta?.redirect,
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/34/nuxt/pages/ledenvoordelen.vue").then(m => m.default || m),
    children: [
  {
    name: afnemeneSfqwAiVPmMeta?.name ?? "ledenvoordelen-slug-afnemen",
    path: afnemeneSfqwAiVPmMeta?.path ?? ":slug()/afnemen",
    meta: afnemeneSfqwAiVPmMeta || {},
    alias: afnemeneSfqwAiVPmMeta?.alias || [],
    redirect: afnemeneSfqwAiVPmMeta?.redirect,
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/34/nuxt/pages/ledenvoordelen/[slug]/afnemen.vue").then(m => m.default || m)
  },
  {
    name: indexuk4Ob1i5CWMeta?.name ?? "ledenvoordelen-slug",
    path: indexuk4Ob1i5CWMeta?.path ?? ":slug()",
    meta: indexuk4Ob1i5CWMeta || {},
    alias: indexuk4Ob1i5CWMeta?.alias || [],
    redirect: indexuk4Ob1i5CWMeta?.redirect,
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/34/nuxt/pages/ledenvoordelen/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexIgRJiR65wlMeta?.name ?? "ledenvoordelen",
    path: indexIgRJiR65wlMeta?.path ?? "",
    meta: indexIgRJiR65wlMeta || {},
    alias: indexIgRJiR65wlMeta?.alias || [],
    redirect: indexIgRJiR65wlMeta?.redirect,
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/34/nuxt/pages/ledenvoordelen/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexVqFuuUsFZSMeta?.name ?? "lid-worden",
    path: indexVqFuuUsFZSMeta?.path ?? "/lid-worden",
    meta: indexVqFuuUsFZSMeta || {},
    alias: indexVqFuuUsFZSMeta?.alias || [],
    redirect: indexVqFuuUsFZSMeta?.redirect,
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/34/nuxt/pages/lid-worden/index.vue").then(m => m.default || m)
  },
  {
    name: uitschrijvenP6LxIhgCOsMeta?.name ?? "nieuwsbrief-uitschrijven",
    path: uitschrijvenP6LxIhgCOsMeta?.path ?? "/nieuwsbrief/uitschrijven",
    meta: uitschrijvenP6LxIhgCOsMeta || {},
    alias: uitschrijvenP6LxIhgCOsMeta?.alias || [],
    redirect: uitschrijvenP6LxIhgCOsMeta?.redirect,
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/34/nuxt/pages/nieuwsbrief/uitschrijven.vue").then(m => m.default || m)
  },
  {
    name: authorizey9R4IM7Z5UMeta?.name ?? "oauth-authorize",
    path: authorizey9R4IM7Z5UMeta?.path ?? "/oauth/authorize",
    meta: authorizey9R4IM7Z5UMeta || {},
    alias: authorizey9R4IM7Z5UMeta?.alias || [],
    redirect: authorizey9R4IM7Z5UMeta?.redirect,
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/34/nuxt/pages/oauth/authorize.vue").then(m => m.default || m)
  },
  {
    name: stylesqyvAxHd62yMeta?.name ?? "styles",
    path: stylesqyvAxHd62yMeta?.path ?? "/styles",
    meta: stylesqyvAxHd62yMeta || {},
    alias: stylesqyvAxHd62yMeta?.alias || [],
    redirect: stylesqyvAxHd62yMeta?.redirect,
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/34/nuxt/pages/styles.vue").then(m => m.default || m)
  },
  {
    name: _91id_930fkYiDhwMFMeta?.name ?? "verkoopkansen-id",
    path: _91id_930fkYiDhwMFMeta?.path ?? "/verkoopkansen/:id()",
    meta: _91id_930fkYiDhwMFMeta || {},
    alias: _91id_930fkYiDhwMFMeta?.alias || [],
    redirect: _91id_930fkYiDhwMFMeta?.redirect,
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/34/nuxt/pages/verkoopkansen/[id].vue").then(m => m.default || m)
  },
  {
    name: indexkJqRqUhM7rMeta?.name ?? "verkoopkansen",
    path: indexkJqRqUhM7rMeta?.path ?? "/verkoopkansen",
    meta: indexkJqRqUhM7rMeta || {},
    alias: indexkJqRqUhM7rMeta?.alias || [],
    redirect: indexkJqRqUhM7rMeta?.redirect,
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/34/nuxt/pages/verkoopkansen/index.vue").then(m => m.default || m)
  },
  {
    name: webshopyUdQ0pzLBtMeta?.name ?? undefined,
    path: webshopyUdQ0pzLBtMeta?.path ?? "/webshop",
    meta: webshopyUdQ0pzLBtMeta || {},
    alias: webshopyUdQ0pzLBtMeta?.alias || [],
    redirect: webshopyUdQ0pzLBtMeta?.redirect,
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/34/nuxt/pages/webshop.vue").then(m => m.default || m),
    children: [
  {
    name: _91product_93qRfMOs1T7uMeta?.name ?? "webshop-category-product",
    path: _91product_93qRfMOs1T7uMeta?.path ?? ":category()/:product()",
    meta: _91product_93qRfMOs1T7uMeta || {},
    alias: _91product_93qRfMOs1T7uMeta?.alias || [],
    redirect: _91product_93qRfMOs1T7uMeta?.redirect,
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/34/nuxt/pages/webshop/[category]/[product].vue").then(m => m.default || m)
  },
  {
    name: indexKDE94fDwNAMeta?.name ?? "webshop-category",
    path: indexKDE94fDwNAMeta?.path ?? ":category()",
    meta: indexKDE94fDwNAMeta || {},
    alias: indexKDE94fDwNAMeta?.alias || [],
    redirect: indexKDE94fDwNAMeta?.redirect,
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/34/nuxt/pages/webshop/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: cartK0mWOqWXEXMeta?.name ?? "webshop-cart",
    path: cartK0mWOqWXEXMeta?.path ?? "cart",
    meta: cartK0mWOqWXEXMeta || {},
    alias: cartK0mWOqWXEXMeta?.alias || [],
    redirect: cartK0mWOqWXEXMeta?.redirect,
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/34/nuxt/pages/webshop/cart.vue").then(m => m.default || m)
  },
  {
    name: checkoutCVFk3a2FxBMeta?.name ?? "webshop-checkout",
    path: checkoutCVFk3a2FxBMeta?.path ?? "checkout",
    meta: checkoutCVFk3a2FxBMeta || {},
    alias: checkoutCVFk3a2FxBMeta?.alias || [],
    redirect: checkoutCVFk3a2FxBMeta?.redirect,
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/34/nuxt/pages/webshop/checkout.vue").then(m => m.default || m)
  },
  {
    name: confirmationE8VwyPxV1yMeta?.name ?? "webshop-confirmation",
    path: confirmationE8VwyPxV1yMeta?.path ?? "confirmation",
    meta: confirmationE8VwyPxV1yMeta || {},
    alias: confirmationE8VwyPxV1yMeta?.alias || [],
    redirect: confirmationE8VwyPxV1yMeta?.redirect,
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/34/nuxt/pages/webshop/confirmation.vue").then(m => m.default || m)
  },
  {
    name: indexWYYwcdwTvfMeta?.name ?? "webshop",
    path: indexWYYwcdwTvfMeta?.path ?? "",
    meta: indexWYYwcdwTvfMeta || {},
    alias: indexWYYwcdwTvfMeta?.alias || [],
    redirect: indexWYYwcdwTvfMeta?.redirect,
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/34/nuxt/pages/webshop/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: zoekenCDT3ppxMxLMeta?.name ?? "zoeken",
    path: zoekenCDT3ppxMxLMeta?.path ?? "/zoeken",
    meta: zoekenCDT3ppxMxLMeta || {},
    alias: zoekenCDT3ppxMxLMeta?.alias || [],
    redirect: zoekenCDT3ppxMxLMeta?.redirect,
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/34/nuxt/pages/zoeken.vue").then(m => m.default || m)
  },
  {
    name: indexD704OlaQYNMeta?.name ?? "bestuurders-evenementen-slug",
    path: indexD704OlaQYNMeta?.path ?? "/bestuurders/evenementen/:slug()",
    meta: indexD704OlaQYNMeta || {},
    alias: indexD704OlaQYNMeta?.alias || [],
    redirect: indexD704OlaQYNMeta?.redirect,
    component: () => import("~/pages/evenementen/[slug]/index.vue").then(m => m.default || m)
  }
]